<template>
  <span :class="cssClass"></span>
</template>

<script>
export default {
  name: 'VueSpacer',
  props: {
    level: {
      type: String,
      default: '5',
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      let component = `h${this.level}`;

      if (this.native === false) {
        component = 'div';
      }

      return component;
    },
    cssClass() {
      const classes = [];

      if (this.isHorizontal) {
        classes.push(this.$style['space-inline'], this.$style[`width-space-${this.level}`]);
      } else {
        classes.push(this.$style['space-block'], this.$style[`height-space-${this.level}`]);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

@each $variation, $space in $palette-spaces {
  .space-block {
    display: flex;
    width: 100%;
  }
  .space-inline {
    display: inline-flex;
    height: 100%;
  }

  .height-#{$variation} {
    height: $space;
  }

  .width-#{$variation} {
    width: $space;
  }
}
</style>
