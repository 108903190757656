<template>
  <div class="racks">
    <div class="top-wrapper">
      <div class="store-info">
        <VueText sizeLevel="4" color="grey-30" weightLevel="3" class="title">Mağaza Adı</VueText>
        <VueText sizeLevel="6" color="grey-40" weightLevel="3"
          >{{ this.getQrResponse.customerName }}
        </VueText>
        <VueSpacer level="15" />
        <VueText
          sizeLevel="4"
          color="grey-30"
          weightLevel="3"
          :class="{ greenText: greenText, ['total']: true }"
          >Toplam Raf Sayısı: {{ this.rackCount }}</VueText
        >
        <VueText sizeLevel="4" color="grey-30" :class="{ greenText }" weightLevel="3"
          >Fotoğraf Çekilen Raf Sayısı: {{ this.racks.length }}</VueText
        >
      </div>
      <div class="progress">
        <div
          class="progress-bar"
          :style="{
            width: percent,
            backgroundImage: bgImage,
          }"
        ></div>
      </div>
      <div class="rack-list">
        <Rack
          v-for="(rack, index) in this.racks"
          :data="rack"
          :key="index"
          @removeRack="removeRack"
        />
        <div class="get-photo-row" v-if="!maxCount">
          <router-link to="/secure/image-recognition/qr-reader">
            <div class="cam-icon-wrapper">
              <VueIcon iconName="IconCameraBig" :width="23" :height="18"></VueIcon>
            </div>
            <VueText weightLevel="3">Diğer rafın fotoğrafını çek</VueText>
          </router-link>
        </div>
      </div>
      <VueText sizeLevel="3" class="info">
        <VueIcon
          class="icon-info"
          :width="icons.infoSmall.width"
          :height="icons.infoSmall.height"
          :iconName="icons.infoSmall.name"
          iconColor="#e5472d"
        ></VueIcon
        >En az {{ this.rackCount }} fotoğraf eklemeniz gerekmektedir. <br />
        1 raf için en fazla 2 fotoğraf yüklenebilir. <br />
        {{ this.maxCountMessage }}</VueText
      >
    </div>

    <BrandButton
      :disabled="disabledButton"
      :size="sizes.xxLarge"
      :contentAlignment="constants.flexAlignment.center"
      type="submit"
      @click="process"
    >
      <div v-if="loading" class="loader">Loading...</div>
      GÖNDER</BrandButton
    >
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import Rack from '@/views/pages/secure/imageRecognition/Rack';
import { mapGetters } from 'vuex';
import ImageRecognition from '@/services/Api/imageRecognition.js';

export default {
  name: 'Racks',
  components: {
    VueText,
    VueIcon,
    BrandButton,
    VueSpacer,
    Rack,
  },
  data: function() {
    return {
      uploading: false,
      fail: false,
      success: false,
    };
  },
  created() {
    this.setBottomBarStatus(false);
  },
  computed: {
    ...mapGetters('app', ['getRacks', 'getQrResponse', 'getLocation']),
    racks() {
      return this.getRacks;
    },
    rackCount() {
      return this.getQrResponse.rackCount;
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    percent() {
      return `${([...new Set(this.racks.map(item => item.rackNumber))].length * 100) /
        this.rackCount}%`;
    },
    bgImage() {
      return (
        [...new Set(this.racks.map(item => item.rackNumber))].length === this.rackCount &&
        `linear-gradient(to right, #83cb1b, #1bc04a 98%)`
      );
    },
    greenText() {
      return (
        [...new Set(this.racks.map(item => item.rackNumber))].length &&
        [...new Set(this.racks.map(item => item.rackNumber))].length === this.rackCount
      );
    },
    disabledButton() {
      return !(
        [...new Set(this.racks.map(item => item.rackNumber))].length === this.rackCount &&
        this.racks.every(i => i.isSuccess)
      );
    },
    maxCountMessage() {
      return this.racks.length > 0 && this.racks.length === this.rackCount * 2
        ? 'Maksimum fotoğraf sayısına ulaştınız, gönderim yapabilirsiniz.'
        : '';
    },
    maxCount() {
      return this.racks.length > 0 && this.racks.length === this.rackCount * 2;
    },
    loading() {
      return (
        [...new Set(this.racks.map(item => item.rackNumber))].length === this.rackCount &&
        !this.racks.every(i => i.isSuccess)
      );
    },
  },
  watch: {
    racks: {
      handler: function() {},
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    removeRack(id) {
      if (id) {
        const confirmModalConfig = {
          isModalOpened: true,
          modalText: '<div style="margin-top: 10px;">Fotoğraf Silinecek.</div>',
          isDoubleButton: true,
          firstButtonText: 'TAMAM',
          firstButtonFn: () => {
            this.$store.dispatch(
              'app/setRacks',
              this.racks.filter(i => i.id !== id),
            );
            this.$store.dispatch('app/setModalConfig', {});
          },
        };
        this.$store.dispatch('app/setModalConfig', confirmModalConfig);
      }
    },
    process() {
      let images = [];
      this.racks.map(i => images.push(...i.images));
      const requestData = {
        images,
        location: this.getLocation,
        customerCode: this.getQrResponse.customerCode,
        rackCount: this.getQrResponse.rackCount,
      };
      const modalConfig = {
        isModalOpened: true,
        isUploading: true,
      };
      this.$store.dispatch('app/setStatusModalConfig', modalConfig);
      ImageRecognition.process(requestData)
        .then(res => {
          if (res.status === 200) {
            this.$store.dispatch('app/setRacks', []);
            const modalConfig = {
              isModalOpened: true,
              isUploading: false,
              isSuccess: true,
            };
            this.$store.dispatch('app/setStatusModalConfig', { isModalOpened: false });
            setTimeout(() => {
              this.$store.dispatch('app/setStatusModalConfig', modalConfig);
            });
          }
        })
        .catch(err => {
          const modalConfig = {
            isModalOpened: true,
            isUploading: false,
            isFail: true,
          };
          this.$store.dispatch('app/setStatusModalConfig', { isModalOpened: false });
          setTimeout(() => {
            this.$store.dispatch('app/setStatusModalConfig', modalConfig);
          });
          if (!err.response.data.Error.Message) {
            ImageRecognition.errorLog({
              postCode: this.getQrResponse.customerCode,
              detail: err,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-wrapper {
  min-height: calc(100vh - 151px);
  display: flex;
  flex-direction: column;
}
.store-info {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.progress {
  display: flex;
  height: 2px;
  overflow: hidden;
  background-color: #e9ecef;
  &-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-image: linear-gradient(315deg, #d20051, #ffa300);
    transition: width 0.6s ease;
  }
}
.title,
.total {
  margin-bottom: 6px;
}
.rack-list {
  padding-left: 20px;
  padding-right: 20px;
}

input[type='file'] {
  display: none;
}
.get-photo-row {
  a {
    display: flex;
    align-items: center;
    margin-top: 17.5px;
    margin-bottom: 20px;
    color: #23303d;
    text-decoration: none;
  }
}
.get-photo {
  cursor: pointer;
}
.cam-icon-wrapper {
  background-image: linear-gradient(315deg, #d20051, #ffa300);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 17px 15px;
  display: flex;
  align-items: center;
  margin-right: 14px;
}
.info {
  margin-top: auto;
  margin-bottom: 18px;
  color: #e5472d;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
  .icon-info {
    margin-right: 5px;
  }
}
.greenText {
  background: -webkit-linear-gradient(85deg, #83cb1b, #1bc04a 98%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loader {
  color: #ffffff;
  font-size: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
  right: 100px;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 9;
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
</style>
